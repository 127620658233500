.app-sidebar {
  width: 25rem;
  left: -25rem;
  z-index: 1001;
}

.app-sidebar.responsive {
  left: 0;
}

@media (min-width: 1000px) {
  .app-sidebar {
    left: 0;
  }
} 